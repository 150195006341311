import React, { useState } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/swiper.min.css";
import { IUserData } from "../../../../interfaces/user/IUserData";
import { MOCK_ARRAY_10 } from "../../../../lib/constants";
import SwiperCore, { Navigation } from "swiper";
import { HomepageSpecialistLoadingCard } from "../../../homepage/HomepageSpecialistCard";
import { SwiperCustomArrow } from "./slidersResources/SwiperCustomArrow";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

SwiperCore.use([Navigation]);

export const HomeSwiper = ({
  data = false,
  Component,
}: {
  data: boolean | IUserData[];
  Component: React.ElementType;
}) => {
  const [activeIndex, setActiveIndex] = useState(2);

  return (
    <Swiper
      navigation={{
        prevEl: "#custom-left-arrow",
        nextEl: "#custom-right-arrow",
      }}
      className="w-full h-[500px]"
      initialSlide={1}
      spaceBetween={10}
      slidesPerView={3}
      centeredSlides={true}
      onSlideChange={(swiper) => {
        setActiveIndex(swiper.activeIndex);
      }}
    >
      {Array.isArray(data) &&
        data?.map((item, i) => {
          return (
            <SwiperSlide
              className={`
            ${
              activeIndex === i
                ? "scale-125 2xl:scale-150 z-40"
                : "opacity-40 scale-75 2xl:scale-110"
            } 
            ${
              activeIndex - 1 === i ||
              activeIndex + 1 === i ||
              activeIndex === i
                ? ""
                : "invisible"
            } 
            transform overflow-visible flex items-center justify-center transition-all duration-200`}
              key={item.id}
            >
              <Component data={item} />
            </SwiperSlide>
          );
        })}

      {!data &&
        MOCK_ARRAY_10.map((item, i) => {
          return (
            <SwiperSlide
              key={item}
              className={`
              transform overflow-visible flex items-center justify-center transition-all duration-200
              ${
                activeIndex === i
                  ? "scale-125 2xl:scale-150 z-40"
                  : "opacity-40 scale-75 2xl:scale-110"
              }`}
            >
              <HomepageSpecialistLoadingCard />
            </SwiperSlide>
          );
        })}
      <SwiperCustomArrow Icon={ChevronLeftIcon} id="custom-left-arrow" />

      <SwiperCustomArrow Icon={ChevronRightIcon} id="custom-right-arrow" />
    </Swiper>
  );
};
