import React, { useRef, useState } from "react";
import { useObjectives } from "../../../../../../hooks/global/useObjectives";
import { IObjective } from "../../../../../../interfaces/globalComponents/filters/filterItems/IObjective";
import { Menu, MenuItem, Stack } from "@mui/material";
import { FilterMenuButton } from "./FilterMenuButton";
import { RootState } from "../../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setIdFilters } from "../../../../../../redux/filtersSlice";
import { FilterCheckIcon } from "../filterCheckIcon/FilterCheckIcon";

export const FilterMenuObjectives = () => {
  const [objectives, setObjectives] = useState<IObjective[]>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);
  const filter = useSelector((state: RootState) => state.filters.listings);
  const dispatch = useDispatch();

  useObjectives({ setObjectives });

  return (
    <Stack>
      <FilterMenuButton
        id="filter-menu-sports"
        label={`Search by Objectives`}
        setOpenDropdown={setOpenDropdown}
        anchorButtonRef={anchorButtonRef}
        isOpenDropdown={openDropdown}
        currentFilterItems={filter.objectives.length}
      />

      <Menu
        MenuListProps={{
          "aria-labelledby": "filter-menu-sports",
        }}
        onClose={() => setOpenDropdown(false)}
        open={openDropdown}
        anchorEl={anchorButtonRef.current}
        disablePortal
      >
        {objectives?.map((item, i) => {
          return (
            <MenuItem
              onClick={() => {
                dispatch(
                  setIdFilters({
                    id: item.id,
                    type: "objectives",
                    filterFor: "listings",
                  })
                );
              }}
              key={i}
            >
              {item.title}
              {filter.objectives.find((filterItem) => filterItem == item.id) !=
              null ? (
                <FilterCheckIcon />
              ) : null}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
