import React from "react";
import { Stack } from "@mui/material";

interface HomepageFirstSectionMainContentProps {
  children: React.ReactNode;
}

export const HomepageFirstSectionMainContent = ({
  children,
}: HomepageFirstSectionMainContentProps) => {
  return (
    <Stack className="flex flex-col lg:items-start justify-center lg:w-[50%] space-y-[40px] lg:space-y-[20px]">
      {children}
    </Stack>
  );
};
