import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const HomepageFirstSectionTextContent = () => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Stack className="space-y-[10px] flex flex-col ">
      <Typography
        variant="h1"
        className="text-[28px] lg:text-[45px] 3xl:text-[60px] font-semibold lg:leading-[52px] text-center lg:text-left"
        sx={{
          color: colors.surface.onSurfaceDetachable,
          "@media (min-width:1240px)": {
            color: colors.surface.onSurface,
          },
        }}
      >
        Optimise preparation,
        <br />
        maximise performance.
      </Typography>

      <Typography
        variant="h2"
        className="text-[16px] lg:text-[24px] 3xl:text-[45px] text-center lg:text-left"
        sx={{
          color: colors.surface.onSurfaceDetachable,
          "@media (min-width:1240px)": {
            color: colors.surface.onSurface,
          },
        }}
      >
        Get access to coaches, plans & programs{" "}
        <br className="hidden 2xl:inline" />
        proven at the highest level.
      </Typography>
    </Stack>
  );
};
