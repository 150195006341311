import React from "react";
import { CoachesFromApi } from "../../../interfaces/specialist/CoachesFromApi";
import { HomepageFirstSectionWrapper } from "./HomepageFirstSectionWrapper";
import { HomepageFirstSectionMainContent } from "./HomepageFirstSectionMainContent";
import { HomepageFirstSectionTextContent } from "./HomepageFirstSectionTextContent";
import { HomepageFirstSectionActions } from "./HomepageFirstSectionActions";
import { HomepageFirstSectionSlider } from "./HomepageFirstSectionSlider";

interface HomepageFirstSectionProps {
  coaches: CoachesFromApi;
}

export const HomepageFirstSection = ({
  coaches,
}: HomepageFirstSectionProps) => {
  return (
    <HomepageFirstSectionWrapper>
      <HomepageFirstSectionMainContent>
        <HomepageFirstSectionTextContent />

        <HomepageFirstSectionActions />
      </HomepageFirstSectionMainContent>

      <HomepageFirstSectionSlider coaches={coaches} />
    </HomepageFirstSectionWrapper>
  );
};
