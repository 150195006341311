import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { FilterMenuSports } from "./filterMenu/FilterMenuSports";
import { FilterSearchButton } from "./filterSearchButton/FilterSearchButton";
import { FilterMenuObjectives } from "./filterMenu/FilterMenuObjectives";
import { FilterMenuLevels } from "./filterMenu/FIlterMenuLevels";
import { FilterDivider } from "./filterDivider/FilterDivider";

export const FilterSearchBar = () => {
  const theme = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Stack className="py-[100px] hidden lg:block">
      <Stack
        sx={{
          border: "1px solid",
          borderColor: theme.outline.outlineVariant,
        }}
        className="flex flex-col items-center 
      justify-center rounded-[60px] py-[40px] px-[60px] space-y-[40px] "
      >
        <Typography sx={{}} className="text-[36px]">
          Get started by selecting your sport or training objectives
        </Typography>

        <Stack
          sx={{
            backgroundColor: theme.outline.outlineVariant,
          }}
          direction="row"
          className="flex items-center justify-between px-[16px] py-[12px] rounded-full w-full 3xl:w-[50%]"
        >
          <Stack
            direction="row"
            className="flex space-x-[16px] items-center justify-center"
          >
            <FilterMenuSports />
            <FilterDivider />
            <FilterMenuObjectives />
            <FilterDivider />
            <FilterMenuLevels />
          </Stack>

          <FilterSearchButton />
        </Stack>
      </Stack>
    </Stack>
  );
};
