import Head from "next/head";
import { useTranslation } from "next-i18next";
import { MaterialLayout } from "../components/global/layout/material/MaterialLayout";
import { useLoggedUser } from "../hooks/user/useLoggedUser";
import { HomepageMaterial } from "../components/homepage/AthleteHomepage/HomepageMaterial";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetServerSidePropsContext } from "next";
import cookie from "cookie";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Library } from "../components/library/Library";

interface IHomepage {
  hasCookieToken: boolean;
}

export default function Homepage({ hasCookieToken }: IHomepage) {
  useLoggedUser();

  const { t } = useTranslation();
  const userRedux = useSelector((state: RootState) => state.user.value);
  const loggedAndRendered = userRedux.loggedUser && userRedux.rendered;

  return (
    <MaterialLayout>
      <Head>
        <title>{t("index:page-title")}</title>
        <meta name="dc.title" content={t("index:page-title")} />
        <meta name="og:title" content={t("index:page-title")} />
        <meta name="twitter:title" content={t("index:page-title")} />

        <link rel="icon" href="/favicon.ico" />
        <meta
          name="description"
          content="POD1UM | Sports Performance | Strength & Conditioning Coaches, Plans & Workouts | Experience Elite"
        />
        <meta
          name="dc.description"
          content="POD1UM | Sports Performance | Strength & Conditioning Coaches, Plans & Workouts | Experience Elite"
        />
        <meta
          name="og:description"
          content="POD1UM | Sports Performance | Strength & Conditioning Coaches, Plans & Workouts | Experience Elite"
        />
        <meta
          name="twiiter:description"
          content="POD1UM | Sports Performance | Strength & Conditioning Coaches, Plans & Workouts | Experience Elite"
        />
      </Head>

      {hasCookieToken || loggedAndRendered ? <Library /> : <HomepageMaterial />}
    </MaterialLayout>
  );
}

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { req, locale } = context;
  let hasCookieToken = false;

  if (req) {
    const cookies = req.headers.cookie ?? "";
    const parsedCookies = cookie.parse(cookies);
    hasCookieToken = parsedCookies["pod1umToken"] ? true : false;
  }

  return {
    props: {
      hasCookieToken,
      ...(await serverSideTranslations(locale, [
        "common",
        "menu",
        "footer",
        "index",
        "log-details",
      ])),
      // allBlogs
    },
  };
};
