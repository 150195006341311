import React, { useRef, useState } from "react";
import { Menu, MenuItem, Stack } from "@mui/material";
import { FilterMenuButton } from "./FilterMenuButton";
import { RootState } from "../../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setTitleFilters } from "../../../../../../redux/filtersSlice";
import { FilterCheckIcon } from "../filterCheckIcon/FilterCheckIcon";
import { allLevels } from "../../../../../../lib/genericFilterData";

export const FilterMenuLevels = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorButtonRef = useRef<HTMLButtonElement>(null);
  const filter = useSelector((state: RootState) => state.filters.listings);
  const dispatch = useDispatch();

  return (
    <Stack>
      <FilterMenuButton
        id="filter-menu-level"
        label={`Search by Level`}
        setOpenDropdown={setOpenDropdown}
        anchorButtonRef={anchorButtonRef}
        isOpenDropdown={openDropdown}
        currentFilterItems={filter.levels.length}
      />

      <Menu
        MenuListProps={{
          "aria-labelledby": "filter-menu-level",
        }}
        onClose={() => setOpenDropdown(false)}
        open={openDropdown}
        anchorEl={anchorButtonRef.current}
      >
        {allLevels?.map((item, i) => {
          return (
            <MenuItem
              onClick={() => {
                dispatch(
                  setTitleFilters({
                    title: item.title,
                    type: "levels",
                    filterFor: "listings",
                  })
                );
              }}
              key={i}
            >
              {item.title}
              {filter.levels.find((filterItem) => filterItem == item.value) !=
              null ? (
                <FilterCheckIcon />
              ) : null}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
