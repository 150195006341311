import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IMaterialCard } from "../../../../interfaces/globalComponents/explore/material/cards/IMaterialCard";
import useImageSize from "../../../../hooks/global/useImageSize";
import Link from "next/link";
import { buildUrlName } from "../../../../lib/generic";
import { CustomChip } from "../../../global/chips/CustomChip";
import { MaterialCardScore } from "./MaterialCardScore";
import { useRouter } from "next/router";
import { ScoreStarRating } from "../../../global/score/material/ScoreStarRating";
import { useTrainingPlanLevelsTextCard } from "../../../../hooks/trainingPlan/useTrainingPlanLevelsTextCard";
import { MaterialCardCoachAvatarCollaborators } from "./MaterialCardCoachAvatarCollaborators";

export const MaterialCard = ({ data }: IMaterialCard) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const { levelsTextCard } = useTrainingPlanLevelsTextCard(data.levels);
  const router = useRouter();
  const resizedImage = useImageSize({
    imageUrl: data.coverImage,
    size: 500,
    isCover: true,
    originalFormat: true,
  })[0];

  const isDarkBackground = router.asPath === "/";
  const url =
    data.type === "training"
      ? "/training-plan/[id]/[name]"
      : "/resources/[id]/[name]";

  return (
    <Link
      href={{
        pathname: url,
        query: {
          id: data?.id,
          name: buildUrlName(data?.title),
        },
      }}
      data-cy="explore-training-plan"
    >
      <Card
        sx={{
          backgroundColor: isDarkBackground
            ? componentColors.surface.surfaceDim
            : componentColors.surface.surfContainer,
          backgroundImage: "none",
        }}
        className="rounded-b-[16px] rounded-t-[8px]"
      >
        <CardMedia
          image={resizedImage}
          className="aspect-video rounded-[8px]"
        />

        <CardContent className="flex flex-col items-start justify-between space-y-[16px] ">
          <Stack>
            <Typography
              variant="body2"
              sx={{
                color: componentColors.surface.onSurfaceVariant,
              }}
              className="capitalize text-[14px]"
            >
              {levelsTextCard}
            </Typography>
            <Typography
              variant="body1"
              className="text-[16px] font-medium"
              sx={{
                color: componentColors.surface.onSurface,
              }}
            >
              {data.title}
            </Typography>

            <ScoreStarRating value={data.score} showValue={false} />
          </Stack>

          <Stack direction="row" spacing={"10px"}>
            {data.trainingPlan && !data.publicListing && (
              <>
                <CustomChip label={`${data.trainingPlan.frequency}x/week`} />
                <CustomChip
                  label={`${data.trainingPlan.duration} week${
                    data.trainingPlan.duration > 1 ? "s" : ""
                  }`}
                />
              </>
            )}
          </Stack>

          <Stack
            direction={"row"}
            className="space-x-[16px] flex justify-start"
          >
            <MaterialCardCoachAvatarCollaborators
              collaborators={data.collaborators}
              coach={data.user}
            />

            <div
              style={{
                paddingLeft:
                  data.collaborators.length * 40 -
                  15 * data.collaborators.length,
              }}
            >
              <Typography
                className="text-[14px] overflow-ellipsis "
                sx={{
                  color: componentColors.surface.onSurface,
                }}
              >
                {data.user.name}
                {data.collaborators.length > 0
                  ? `, with ${data.collaborators[0].title}`
                  : null}
                {data.collaborators.length === 2
                  ? ` & ${data.collaborators[1].title}`
                  : null}
              </Typography>

              <MaterialCardScore score={data.user?.score} />
            </div>
          </Stack>
        </CardContent>
      </Card>
    </Link>
  );
};
