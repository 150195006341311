import React from "react";
import { PrimaryButton } from "../../global/buttons/material/primaryButton/PrimaryButton";
import { setTabSection } from "../../../redux/pages/exploreSlice";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { Stack } from "@mui/material";
import { SecondaryButton } from "../../global/buttons/material/secondaryButton/SecondaryButton";

export const HomepageFirstSectionActions = () => {
  const dispatch = useDispatch();

  return (
    <Stack
      direction={"row"}
      className="lg:space-x-[20px] lg:space-y-0 lg:mt-[60px] flex flex-col lg:flex-row w-full space-y-[16px]"
    >
      <Link href="/explore">
        <PrimaryButton
          title="Free Workouts"
          onClick={() => dispatch(setTabSection(2))}
          className="px-[29px] w-full lg:w-auto font-semibold"
        />
      </Link>

      <Link href="/explore">
        <SecondaryButton
          title="Find a Coach"
          onClick={() => dispatch(setTabSection(1))}
          className="px-[29px] w-full lg:w-auto font-semibold"
        />
      </Link>
    </Stack>
  );
};
