import { Stack } from "@mui/material";
import React from "react";
import { HomepageSpecialistCard } from "../HomepageSpecialistCard";
import { HomeSwiper } from "../../global/sliders/material/HomeSwiper";
import { CoachesFromApi } from "../../../interfaces/specialist/CoachesFromApi";

interface HomepageFirstSectionSliderProps {
  coaches: CoachesFromApi;
}

export const HomepageFirstSectionSlider = ({
  coaches,
}: HomepageFirstSectionSliderProps) => {
  return (
    <Stack className="hidden lg:block lg:w-[50%] 2xl:w-[56%]">
      <HomeSwiper data={coaches.rows} Component={HomepageSpecialistCard} />
    </Stack>
  );
};
