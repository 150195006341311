import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Paper, Stack } from "@mui/material";

interface HomepageFirstSectionWrapperProps {
  children: React.ReactNode;
}

export const HomepageFirstSectionWrapper = ({
  children,
}: HomepageFirstSectionWrapperProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Paper
      sx={{
        bgcolor: theme.componentColors.surface.surfContainerLowest,
        backgroundImage: "none",
      }}
      className="py-[40px] px-[16px] rounded-2xl lg:py-[87px]"
    >
      <Stack className="lg:h-[450px] space-y-[30px] flex flex-col lg:flex-row lg:px-[32px] lg:space-x-5 items-center justify-center">
        {children}
      </Stack>
    </Paper>
  );
};
