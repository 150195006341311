import React from "react";
import { HomepageListingSlider } from "../Sliders/HomepageListingSlider";
import DraggableSlider from "../../global/sliders/DraggableSlider";
import { MaterialCardLoading } from "../../explore/material/cards/MaterialCardLoading";
import { MOCK_ARRAY_5 } from "../../../lib/constants";
import { ListingsFromApi } from "../../../interfaces/training-plan/ListingsFromApi";

interface HomepageWorkoutsSliderProps {
  workouts: ListingsFromApi;
}

export const HomepageWorkoutsSection = ({
  workouts,
}: HomepageWorkoutsSliderProps) => {
  if (workouts?.rows?.length > 0) {
    return (
      <HomepageListingSlider
        listings={workouts}
        title="Free Workouts"
        subtitle="Experience the Difference."
        url="/explore"
        exploreTabSection={2}
      />
    );
  }

  return (
    <DraggableSlider
      data={MOCK_ARRAY_5}
      Component={MaterialCardLoading}
      showTitle={false}
      disableArrows
    />
  );
};
