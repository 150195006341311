import React from "react";

interface HomepageSecondSectionWrapperProps {
  children: React.ReactNode;
}

export const HomepageSecondSectionWrapper = ({
  children,
}: HomepageSecondSectionWrapperProps) => {
  return (
    <div
      data-cy="homepage-second-web-section"
      className=" flex flex-col px-4 md:px-8 lg:px-16 lg:mx-auto lg:items-center 
          lg:justify-center lg:pt-[100px] space-y-10"
    >
      {children}
    </div>
  );
};
